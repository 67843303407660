import './App.scss';
import background from './assets/bg.png';
import profileImg from './assets/profile.jpg';
import sigla from './assets/sigla.png';

import DressUpMockup from "./assets/mockup/DressUpMockup.png";
import BLDateMockup from "./assets/mockup/BLDateMockup.png";
import StockForecastingMockup from "./assets/mockup/StockForecastingMockup.png";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { SocialIcon } from "react-social-icons";

import ProjectCard from './components/ProjectCard.component';

function App() {

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 1
    }
  };

  return (
    <div className="App">
    
      <nav>
        <div className="left">

          <h3 className='nume'>Gherman Raul</h3>
          <h4 className="ocupatie">Student</h4>

        </div>
        <div className="right">
          <a href="#about">About</a>
          <a href="#projects">Projects</a>
          {/* <a href="#contact">Contact</a> */}
        </div>
      </nav>

      <div className="presentaion">
        <div className="me">
          <img src={profileImg} alt=""></img>
          <h1>Gherman Raul</h1>
        </div>
        <div className='text'>
          <p>Passionate and ambitious Computer Science student. With a deep-rooted love for technology and a knack for problem-solving, I am constantly seeking opportunities to expand my skills.</p>
          <div className="socials">
            <SocialIcon
              url="https://github.com/RaulGhr"
              bgColor="none"
              fgColor="white"
            />
            <SocialIcon
              url="https://www.linkedin.com/in/raul-gherman-4b6aa21bb/"
              bgColor="none"
              fgColor="white"
            />
            <SocialIcon
              url="https://www.instagram.com/raul.gherman2804/"
              bgColor="none"
              fgColor="white"
            />
          </div>
        </div>
      </div>
      
      <div id="projects"></div>
      <div className="projects">
      <h1>Projects</h1>
        <Carousel responsive={responsive} 
        itemClass='card-item' 
        sliderClass='carousell-slider'
        containerClass='carousel-container'>
              <ProjectCard
                title={"DressUp"}
                description={"Wardrobe Organizer"}
                technologies={[
                    {tech: "https://img.shields.io/badge/React-61DAFB?logo=react&style=for-the-badge&logoColor=white&color=black"},
                    {tech: "https://img.shields.io/badge/Python-339933?logo=python&style=for-the-badge&logoColor=white&color=black"},
                    {tech: "https://img.shields.io/badge/AI-fffff?style=for-the-badge&logoColor=white&color=black"}
                ]}
                mockup={DressUpMockup}
                github={"https://github.com/RaulGhr/DressUp"}
              /> 

              <ProjectCard
                title={"BLDate"}
                description={"Valentine Finder"}
                technologies={[
                    {tech: "https://img.shields.io/badge/PHP-61DAFB?logo=PHP&style=for-the-badge&logoColor=white&color=black"},
                    {tech: "https://img.shields.io/badge/800 Users-fffff?style=for-the-badge&logoColor=white&color=black",
                      highlight: true
                    }
                ]}
                mockup={BLDateMockup}
                github={"https://github.com/RaulGhr/BlDate"}
              />

              <ProjectCard
                title={"Stock Forecasting"}
                description={"AI stock forecasting model"}
                technologies={[
                    {tech: "https://img.shields.io/badge/Python-339933?logo=python&style=for-the-badge&logoColor=white&color=black"},
                    {tech: "https://img.shields.io/badge/AI-fffff?style=for-the-badge&logoColor=white&color=black"}
                ]}
                mockup={StockForecastingMockup}
                github={"https://github.com/RaulGhr/StockForecastingAi"}
              />

         
        </Carousel>
      </div>

      <div id="about"></div>
      <div className="about" >
        <h1>About Me</h1>
          <div className="cards">
            <div className="experienceCard volunteerCard aboutCard cardBackground">
              <h2>Experince</h2>
              <div className="places">
                <div className="place">
                  <div className="st">
                    <span className="dot"></span>
                    <div className="vl"></div>
                  </div>
                  <div className="dr">
                    <h2>Intern Full Stack Developer</h2>
                    <h3>{"July 2024 -> Sept 2024"}</h3>
                    <br></br>
                    <h3>As part of an R&D project involving SPIE ICS, a research lab, and final clients, my role was to develop a real-time Network Detection and Response(NDR) cyber security application. This application allows users to visualize network performance, traffic, and device details. Additionally, I was responsible for integrating an AI model into the app to alert users to network attacks and identify targeted devices. The overall goal of the project is to create an AI model capable of detecting network anomalies and attacks.</h3>
                    <br></br>
                    <h3>Technologies used: React, Python, MariaDB</h3>

                  </div>
                </div>

              </div>
            
            </div>
            <div className = "firstRow">
              <div className="skillsCard aboutCard cardBackground">
                <h2>Skills</h2>
                <div className="places">
                  <div className="place">
                    <div className="st">
                      <span className="dot"></span>
                    </div>
                    <div className="dr">
                      <h2>Web Technologies</h2>
                      <h3>HTML/CSS, JavaScript, React</h3>
                    </div>
                  </div>

                  <div className="place">
                    <div className="st">
                      <span className="dot"></span>
                    </div>
                    <div className="dr">
                      <h2>Object Oriented Languages</h2>
                      <h3>Python, Java, C++, C#</h3>
                    </div>
                  </div>

                  <div className="place">
                    <div className="st">
                      <span className="dot"></span>
                    </div>
                    <div className="dr">
                      <h2>DataBases</h2>
                      <h3>MySql, InfluxDB(NoSql)</h3>
                    </div>
                  </div>

                  <div className="place">
                    <div className="st">
                      <span className="dot"></span>
                    </div>
                    <div className="dr">
                      <h2>Cloud Platforms</h2>
                      <h3>AWS, Google Cloud</h3>
                    </div>
                  </div>

                  <div className="place">
                    <div className="st">
                      <span className="dot"></span>
                    </div>
                    <div className="dr">
                      <h2>AI</h2>
                      <h3>General knowledge</h3>
                    </div>
                  </div>

                  <div className="place">
                    <div className="st">
                      <span className="dot"></span>
                    </div>
                    <div className="dr">
                      <h2>Computer Networks</h2>
                      <h3>General knowledge</h3>
                    </div>
                  </div>

                  <div className="place">
                    <div className="st">
                      <span className="dot"></span>
                    </div>
                    <div className="dr">
                      <h2>Others</h2>
                      <h3>GitHub, Docker</h3>
                    </div>
                  </div>
                  
                </div>
              
              </div>

              <div className="educationCard aboutCard cardBackground">
                <h2>Education</h2>
                <div className="places">
                  <div className="place">
                    <div className="st">
                      <span className="dot"></span>
                      <div className="vl"></div>
                    </div>
                    <div className="dr">
                      <h2>University</h2>
                      <h3>Babes-Bolyai University of Cluj-Napoca</h3>
                      <h3>Mathematics and Computer Science</h3>
                      <h3>Bachelor in Computer Science</h3>
                      <h3>{"Oct 2022 -> Present"}</h3>

                    </div>
                  </div>
                  <div className="place">
                    <div className="st">
                      <span className="dot"></span>
                      <div className="vl"></div>
                    </div>
                    <div className="dr">
                      <h2>High School</h2>
                      <h3>Colegiul National "Horea, Closca si Crisan"</h3>
                      <h3>Mathematics and Computer Science</h3>
                      <h3>{"2018 -> 2022"}</h3>

                    </div>
                  </div>
                </div>
              
              </div>              
            </div>

            <div className="volunteerCard aboutCard cardBackground">
              <h2>Volunteer</h2>
              <div className="places">

                <div className="place">
                  <div className="st">
                    <span className="dot"></span>
                  </div>
                  <div className="dr">
                    <h2>Participation in First Tech Challenge with Robotics Team XEO</h2>
                    <h3>The competition consists of designing, building, and programming robots that need to complete specific challenges, emphasizing interaction between teams and event organization. I contributed to the construction of the robot and took on the responsibility of being the robot’s driver.</h3>
                  </div>
                </div>

                <div className="place">
                  <div className="st">
                    <span className="dot"></span>
                  </div>
                  <div className="dr">
                    <h2>Member of Interact Alba Iulia</h2>
                    <h3>Established a dedication to civic engagement and teamwork through my involvement in Interact Club, I contributed to organizing and executing volunteer projects aimed at enhancing the well-being of the local community.</h3>
                  </div>
                </div>

                <div className="place">
                  <div className="st">
                    <span className="dot"></span>
                  </div>
                  <div className="dr">
                    <h2>Volunteer at an adventure park</h2>
                    <h3>My role was to provide assistance, support, and ensure the safety and enjoyment of visitors.</h3>
                  </div>
                </div>

            
                
              </div>
            
            </div>

          </div>
        
      </div>

      <footer>
        <div className='links'>
          {/* <a>GitHub</a>
          <a>LinkedIn</a>
          <a>Instagram</a> */}
            <SocialIcon
              url="https://github.com/RaulGhr"
              bgColor="none"
              fgColor="white"
            />
            <SocialIcon
              url="https://www.linkedin.com/in/raul-gherman-4b6aa21bb/"
              bgColor="none"
              fgColor="white"
            />
            <SocialIcon
              url="https://www.instagram.com/raul.gherman2804/"
              bgColor="none"
              fgColor="white"
            />
        </div>
        <h3>©All Rights Reserved 2024.</h3>
      </footer>

      

      
          
      
    </div>
  );
}

export default App;
