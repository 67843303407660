import '../App.scss';

const ProjectCard = ({title, description, technologies, mockup, github}) => {

    const flipHandler = () => {}
    
    return (
        <div className='flip-card'>
            <div className='flip-card-inner'>
                {/* <div className='flip-card-back'>
                    <div className='cardBackground card'>
                        <p>This application is designed to help you efficiently organize your clothing items, create stylish outfits, and share them with friends for feedback.</p>
                        <h3>Features:</h3>
                        <p>-Capture clothing items: Take photos of your clothing items, and the AI-powered system will intelligently recognize them, remove backgrounds, compress, and resize images for efficient storage.
                            <br/>
                            -Efficient organization: Seamlessly organize your clothing collection in a database, accessible anytime, anywhere.
                            <br/>
                            -Create outfits: Mix and match your clothes to create the perfect outfit for any occasion.
                            <br/>
                            -Share outfits: Share your curated outfits with friends and solicit feedback to explore new styles and trends.
                        </p>
                    </div>
                </div> */}
                <div className="cardBackground card flip-card-front">
                    <div className="text">  
                    <h2>{title}</h2>
                    <h3>{description}</h3>
                    <div className="technologies">
                        {
                            technologies.map(({tech, highlight}, index) => {
                                return <img key={index} src={tech}></img>
                            })
                        }        
                    </div>
                    </div>
                    <div className="mockup">
                        <img src={mockup} alt=""></img>
                    </div>
                    <p onClick={() => window.location.href = github}>More information</p>
                    {/* <p onClick={flipHandler}>More information</p> */}
                </div>
                
            </div>
        </div>
    )
};


export default ProjectCard;